<template>
    <div class="node">
        <v-list-item
            :to="item.type === 'link' ? '' : '/dashboard/' + endpoint"
            :href="item.type === 'link' ? endpoint : ''"
            :target="item.type === 'link' ? '_blank' : '_self'"
            v-if="!isList"
            :class="[$route.name === name ? 'secondary active-item' : '', !drawer_mini && isChild ? 'pl-6':'']"
            class="transition-cubic"
            color="transparent"
            active-class="secondary active-item"
        >
            <v-list-item-icon class="mr-2">
                <v-icon
                        size="16"
                        :color="$route.name === name || isChildOpen ? 'white' : 'sidebar lighten-4'"
                >
                    {{ icon }}
                </v-icon>
            </v-list-item-icon>

            <div
                    class="paragraph text-truncate py-1"
                    :class="$route.name === name || isChildOpen ? 'white--text font-weight-bold': 'sidebar--text text--lighten-5'"
                    :title="label"
            >
                {{ label | firstCapital }}
            </div>
        </v-list-item>

        <v-list-group
                v-else
                :append-icon="icon"
                @click="drawer_mini = false"
                v-model="expanded"
                :class="isChildOpen ? 'active-group-item' : 'transparent'"
                color="transparent"
        >
            <template v-slot:appendIcon>
                <v-icon size="20" :color="$route.name === name || isChildOpen ? 'white': (expanded ? 'white':'sidebar lighten-4')" style="margin-left: -8px;">
                    mdi-chevron-down
                </v-icon>
            </template>

            <template v-slot:activator>
                <v-list-item class="px-0 text-truncate" :class="drawer_mini ? '' : (isChild ? 'pl-2':'')">
                    <v-list-item-icon class="mr-2">
                        <v-icon
                                size="16"
                                :color="$route.name === name || isChildOpen ? 'white': (expanded ? 'white':'sidebar lighten-4')"
                        >
                            {{ icon }}
                        </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content
                            class="paragraph pr-0"
                            :class="$route.name === name || isChildOpen ? 'white--text font-weight-bold': expanded ? 'white--text':'sidebar--text text--lighten-5'"
                            :title="label"
                    >
                        <div class="text-truncate py-1">{{ label | firstCapital }}</div>
                    </v-list-item-content>
                </v-list-item>
            </template>

            <v-list class="sidebar lighten-1 py-0" style="box-shadow: 3px 5px 5px rgba(0,0,0,0.2) inset">
                <NodeTree
                    v-for="(sub, j) in subItems"
                    v-if="sub.position === 'sidebar'"
                    :key="j"
                    :isChild="true"
                    :isList="sub.type === 'menu'"
                    :name="sub.name"
                    :icon="sub.icon"
                    :endpoint="sub.endpoint"
                    :subItems="sub.children"
                    :profondeur="profondeur + 1"
                    :label="sub.label"
                    :item="sub"
                />
            </v-list>
        </v-list-group>
    </div>
</template>

<script>
export default {
    name: "NodeTree",
    props: ['isChild', 'isList', 'name', 'endpoint', 'icon', 'subItems', 'profondeur', 'label', 'item'],
    data(){
        return {
            expanded: false,
            isChildOpen: false,
            colors: ['indigo', 'cyan', 'amber']
        }
    },
    computed: {
        drawer_mini: {
            get(){
                return this.$store.getters['dashboard/drawer_mini']
            },
            set(val){
                this.$store.commit('dashboard/CHANGE_DRAWER_MINI_VAL', val)
            }
        }
    },
    watch: {
        '$route'(){
            this.isChildOpen = false
            this.expanded = false
            this.recChildOpen(this.item.children)
        }
    },
    methods: {
        recChildOpen(children){
            if(this.$route.name !== this.item.name) {
                for (let i = 0; i < children.length; i++) {
                    if (this.$route.name === children[i].name) {
                        this.isChildOpen = true
                        this.expanded = true
                    } else {
                        this.recChildOpen(children[i].children)
                    }
                }
            }
        }
    },
    created() {
        this.recChildOpen(this.item.children)
    }
}
</script>
<style scoped>
    .node >>> .v-list-group__header__append-icon {
        margin-left: 0 !important;
    }
    .active-item {
        border-left: 7px solid rgba(255, 255, 255, 0.5) !important;
        box-shadow: 3px 5px 5px rgba(0,0,0,0.2);
    }

    .active-group-item {
        background-color: var(--v-sidebar-lighten1);
    }
</style>
